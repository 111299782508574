<template>
  <a-config-provider :locale="locale">
     <template #renderEmpty>
        <div style="text-align: center">
          <!-- <img :src="img" alt=""> -->
          <p>暂无数据</p>
        </div>
      </template>
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import locale from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  data() {
    return {
      locale
    }
  }
}
</script>
<style></style>
