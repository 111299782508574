/*
 * @Author: xr
 * @Date: 2022-01-25 17:02:13
 * @LastEditors: xr
 * @LastEditTime: 2022-03-01 13:34:16
 * @FilePath: \postStation-office\src\router\index.js
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//解决vue路由重复导航错误
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


export const constantRoutes = [
  {
    path: '/',
    redirect: '/index'
  }, {
    path: "/",
    name: "Home",
    component: () => import('@/views/home'),
    children:[
      {
        path: "/index",
        name: "index",
        meta: {
            title: '首页'
        },
        component: () => import ("@views/index.vue")
      },
      {
        path: "/register",
        name: "register",
        meta: {
            title: '注册'
        },
        component: () => import ("@views/register.vue")
      },
      {
        path: "/news",
        name: "news",
        meta: {
            title: '新闻资讯'
        },
        component: () => import ("@views/newsInformation.vue")
      },
      {
        path: "/resource",
        name: "resource",
        meta: {
            title: '资源库'
        },
        component: () => import ("@views/resource.vue")
      },
      {
        path: "/resource-list",
        name: "resourceList",
        meta: {
            title: '资源库列表'
        },
        component: () => import ("@views/resourceList.vue")
      },
      {
        path: "/result",
        name: "result",
        meta: {
            title: '需求库'
        },
        component: () => import ("@views/result.vue")
      },
      {
        path: "/demandType",
        name: "demandType",
        meta: {
          title: '需求库'
        },
        component: () => import ("@views/demand/demandTypeList.vue")
      },
      {
        path: "/professional",
        name: "professional",
        meta: {
            title: '专家库'
        },
        component: () => import ("@views/professional.vue")
      },
      {
        path: "/download",
        name: "download",
        meta: {
            title: '下载中心'
        },
        component: () => import ("@views/download.vue")
      },
      {
        path: "/detail",
        name: "detail",
        meta: {
            title: '项目详情'
        },
        component: () => import ("@views/detail.vue")
      },
      {
        path: "/news-detail",
        name: "newsDetail",
        meta: {
            title: '新闻详情'
        },
        component: () => import ("@views/newsDetail.vue")
      },
      {
        path: "/investment",
        name: "investment",
        meta: {
            title: '招商政策'
        },
        component: () => import ("@views/subordinate/investment.vue")
      },
      {
        path: "/regular",
        name: "regular",
        meta: {
            title: '政策法规'
        },
        component: () => import ("@views/subordinate/regular.vue")
      },
      {
        path: "/industry",
        name: "industry",
        meta: {
          title: '行业信息'
        },
        component: () => import ("@views/industryInformation.vue")
      },
      {
        path: "/customService",
        name: "customService",
        meta: {
            title: '客服中心'
        },
        component: () => import ("@views/customService.vue")
      },
      {
        path: "/search",
        name: "search",
        meta: {
            title: '检索'
        },
        component: () => import ("@views/search.vue")
      },
      {
        path: "/us",
        name: "us",
        meta: {
            title: '关于我们'
        },
        component: () => import ("@views/us.vue")
      },
      {
        path: "/professional-detail",
        name: "professionalDetail",
        meta: {
            title: '专家详情'
        },
        component: () => import ("@views/professionalDetail.vue")
      },
      {
        path: "/declaration",
        name: "declaration",
        meta: {
            title: '网站声明'
        },
        component: () => import ("@views/declaration.vue")
      },
      {
        path: "/retrieve-password",
        name: "RetrievePassword",
        meta: {
            title: '找回密码'
        },
        component: () => import ("@views/retrievePassword.vue")
      },
      {
        path: "/famous-enterprise",
        name: "FamousEnterprise",
        meta: {
            title: '明星企业'
        },
        component: () => import ("@views/famousEnterprise.vue")
      },
      {
        path: "/talent",
        name: "talent",
        meta: {
            title: '人才招聘'
        },
        component: () => import ("@views/talent.vue")
      },,
      {
        path: "/talent-list",
        name: "talentList",
        meta: {
            title: '人才招聘'
        },
        component: () => import ("@views/talentList.vue")
      },
    ]

  },

]
const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({y: 0}),
  mode: 'history',
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
