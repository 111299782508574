/*
 * @Author: xr
 * @Date: 2022-01-26 14:27:08
 * @LastEditors: xr
 * @LastEditTime: 2022-03-03 14:49:30
 * @FilePath: \postStation-office\src\main.js
 */

import Vue from 'vue'
// import Cookies from 'js-cookie'
import Antd from 'ant-design-vue'

import App from './App'
// import store from './store'
import router from './router'
import 'ant-design-vue/dist/antd.css'
import { ConfigProvider } from 'ant-design-vue'

Vue.use(Antd)
Vue.use(ConfigProvider)

// register global utility filters
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  // store,
  render: h => h(App)
})
